import { DEFAULT_CITY } from '@/config/defaults';
import { DEFAULT_LANGUAGE } from '@rental/prebooking-ui-utils';
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const IndexPage = () => {
  useEffect(() => {
    navigate(`${DEFAULT_LANGUAGE}/${DEFAULT_CITY}`);
  }, []);
  return <div />;
};

export default IndexPage;
